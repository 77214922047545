import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import 'aos/dist/aos.css';
import AOS from 'aos';






function App() {

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    AOS.init(); // Initialize AOS library on component mount
  }, []);

  scrollSpy.update();



  return (
    <div className="App ">
      <div className='header w-full px-5 py-4 sticky top-0 z-10 '>
          <div className='lg:flex justify-between items-center w-full hidden' data-aos="fade-up"> 
         
            <a href='/' className='relative'>
              <img src='img/Logo.svg' className='' />
            </a>
            <div className='relative flex items-center'>
              <ul className=' items-center gap-x-9 text-white md:mr-10  lg:flex hidden'>
                <li><a href='#AboutSection' className='text-4xl' >About</a></li>
                <li><a href='#Tokonomics' className='text-4xl'>Tokonomics</a></li>
                <li><a href='#BuyPopeye' className='text-4xl'>How To Buy</a></li>
                <li><a href='#airdrop' className='text-4xl'>Airdrop</a></li>
                <li><a href='#RoadMap' className='text-4xl'>Roadmap</a></li>
              </ul>
              <button className='rounded-full md:py-3 md:px-10 px-10 text-brandRed bg-white md:text-4xl text-2xl relative'>Buy Soon</button>
             
            </div>
            
          </div>

          <div className='lg:hidden flex items-center justify-between'>
          <a href='/' className='relative'>
              <img src='img/Logo.svg' className='' />
            </a>
            <button className='rounded-full py-3 md:px-10 px-6 text-brandRed bg-white text-2xl relative'>Buy Soon</button>
              <div onClick={() => setIsActive(true)}>
                <img  src='img/menu-icon.svg' />
              </div>
          </div>


             
        </div>
      <div className='hero-section relative -mt-[1px]'>
      
        
        
   
       

    {isActive === true && (
 <div className='menuMobile fixed z-20 w-full bg-brandRed top-0 min-h-screen overflow-auto  flex-col flex'>
 <div className='mb-10'>
   <div className='lg:hidden flex items-center justify-between bg-[#868EB5]  p-4 px-5'>
   <a href='/' className='relative'>
       <img src='img/Logo.svg' className='' />
     </a>
     <button className='rounded-full py-3 md:px-10 px-6 text-brandRed bg-white text-2xl relative'>Buy Soon</button>
     <div onClick={() => setIsActive(false)}>
         <img src='img/close-menu.svg' />
       </div>
   </div>

   <ul className=' items-center gap-x-9 text-white flex flex-col  p-5'>
     <li className='block text-center py-3 border-b border-white w-full' onClick={() => setIsActive(false)}><a href='#AboutSection' className='text-2xl '>About</a></li>
     <li className='block text-center py-3 border-b border-white w-full' onClick={() => setIsActive(false)}><a href='#Tokonomics' className='text-2xl '>Tokonomics</a></li>
     <li className='block text-center py-3 border-b border-white w-full' onClick={() => setIsActive(false)}><a href='#BuyPopeye' className='text-2xl '>How To Buy</a></li>
     <li className='block text-center py-3 border-b border-white w-full' onClick={() => setIsActive(false)}><a href='#airdrop' className='text-2xl '>Airdrop</a></li>
     <li className='block text-center py-3 border-b border-white w-full' onClick={() => setIsActive(false)}><a href='#RoadMap' className='text-2xl '>Roadmap</a></li>
   </ul>
   </div>
<div>
   <div className='mt-5'>
     <img src='img/Hero-Image.svg' className=' max-w-[120px] mx-auto w-full' />
   </div>

   <div className='flex items-center justify-center px-4 filterBright relative z-10  pb-5'>
     <ul className='mt-5 flex'>
         {/* <li className='md:pr-4 pr-2 inline-block'><a href='#' className='f-bright'><img src='img/social-icons/icon-1.svg'/></a></li> */}
           <li className='md:px-4 px-2 inline-block'><a href='https://twitter.com/PopeyeWasTaken'  target='_blank' className='f-bright w-12'><img src='img/social-icons/icon-2.svg'/></a></li>
           <li className='md:px-4 px-2 inline-block'><a href='https://t.me/PopeyeCoinWasTaken'  target='_blank' className='f-bright w-12'><img src='img/social-icons/icon-3.svg'/></a></li>
           <li className='md:px-4 px-2 inline-block'><a href='#'  target='_blank' className='f-bright w-12'><img src='img/social-icons/icon-4.svg'/></a></li>
           {/* <li className='md:px-4 px-2 inline-block'><a href='#' className='f-bright'><img src='img/social-icons/icon-5.svg'/></a></li>
           <li className='md:px-4 px-2 inline-block'><a href='#' className=''><img src='img/social-icons/icon-6.svg'/></a></li>
           <li className='md:pl-4 pl-2 inline-block'><a href='#' className='f-bright'><img src='img/social-icons/icon-7.svg'/></a></li> */}
     </ul>
 </div>
</div>
</div>
    )}
       
        

       

          <div className='w-full relative z-10 '>
            <div className='container mx-auto max-w-7xl'>
              <div className='grid lg:grid-cols-5 gap-x-20 lg:min-h-[700px] items-center'>
                <div className='md:col-span-2 lg:mt-0 mt-16 lg:mb-0 mb-11' data-aos="slide-left">
                  <img src='img/Hero-Image.svg' className='lg:w-auto md:w-1/2 mx-auto max-w-full w-1/2'  />
                </div>
                <div className='col-span-3 lg:block hidden' data-aos="slide-right">
                  <div className='bg-brandYellow border border-black rounded-3xl py-10 px-14 text-left max-w-2xl ml-auto'>
                    <h3 className='text-5xl max-w-sm mb-5'>"I yam what I yam an'
    tha's all I yam"</h3>
    <h4 className='text-base font-asap max-w-md'>Welcome to the official <span className='font-semibold'>$PEYE</span> coin website! It’s a pleasure to have you here.</h4>
                  <ul className='mt-5'>
                  {/* <li className='pr-4 inline-block'><a href='#' className=''><img src='img/social-icons/icon-1.svg'/></a></li> */}
              <li className='px-4 inline-block'><a href='https://twitter.com/PopeyeWasTaken'  target='_blank' className=''><img src='img/social-icons/icon-2.svg'/></a></li>
              <li className='px-4 inline-block'><a href='https://t.me/PopeyeCoinWasTaken'  target='_blank' className=''><img src='img/social-icons/icon-3.svg'/></a></li>
              <li className='px-4 inline-block'><a href='#' className=''  target='_blank'><img src='img/social-icons/icon-4.svg'/></a></li>
              {/* <li className='px-4 inline-block'><a href='#' className=''><img src='img/social-icons/icon-5.svg'/></a></li>
              <li className='px-4 inline-block'><a href='#' className=''><img src='img/social-icons/icon-6.svg'/></a></li>
              <li className='px-4 inline-block'><a href='#' className=''><img src='img/social-icons/icon-7.svg'/></a></li> */}
                  </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
         

          <div className='w-full relative z-10' data-aos="zoom-in">
            <div className='container mx-auto text-center'>
            <h3 className='text-5xl  mb-12 lg:text-white text-black text-center'>Listed on...</h3>
            
              <div className='lg:flex items-center justify-center gap-7 filter blur'>
                <a href='#' className='md:mb-0 mb-4 mx-4 inline-block'><img className='md:w-auto w-10/12 mx-auto' src='img/bybit.png' /></a>
                <a href='#' className='md:mb-0 mb-4 mx-4 inline-block'><img className='md:w-auto w-10/12 mx-auto' src='img/bitget.png' /></a>
                <a href='#' className='md:mb-0 mb-4 mx-4 inline-block'><img className='md:w-auto w-10/12 mx-auto' src='img/uniswap.png' /></a>
                <a href='#' className='md:mb-0 mb-4 mx-4 inline-block'><img className='md:w-auto w-10/12 mx-auto' src='img/coincap.png' /></a>
              </div>
            </div>
          </div>

          <div className='lg:hidden flex items-center justify-center px-4 filterBright relative z-10'>
            <ul className='mt-5 flex'>
              

              {/* <li className='md:pr-4 pr-2 inline-block'><a href='#' className='f-bright'><img src='img/social-icons/icon-1.svg'/></a></li> */}
                    <li className='md:px-4 px-2 inline-block'><a href='https://twitter.com/PopeyeWasTaken'  target='_blank' className='f-bright'><img src='img/social-icons/icon-2.svg'/></a></li>
                    <li className='md:px-4 px-2 inline-block'><a href='https://t.me/PopeyeCoinWasTaken'  target='_blank' className='f-bright'><img src='img/social-icons/icon-3.svg'/></a></li>
                    <li className='md:px-4 px-2 inline-block'><a href='#'  target='_blank' className='f-bright'><img src='img/social-icons/icon-4.svg'/></a></li>
                    {/* <li className='md:px-4 px-2 inline-block'><a href='#' className='f-bright'><img src='img/social-icons/icon-5.svg'/></a></li>
                    <li className='md:px-4 px-2 inline-block'><a href='#' className=''><img src='img/social-icons/icon-6.svg'/></a></li>
                    <li className='md:pl-4 pl-2 inline-block'><a href='#' className='f-bright'><img src='img/social-icons/icon-7.svg'/></a></li> */}
            </ul>
          </div>
      </div>

      <div className='w-full px-5 md:py-20 mt-20  mx-auto' id="AboutSection" data-aos="zoom-in">
      <div className='mx-auto lg:hidden -bottom-10 relative '>
              <img src='img/popeye-image.svg' className='md:w-1/2 w-10/12  mx-auto'/>
            </div>
        <div className='bg-brandYellow md:px-16 md:py-12 p-6 rounded-3xl border border-black relative'>
            
          <div className='grid lg:grid-cols-3 gap-4'>
            <div className='col-span-2 md:text-left'>
              <h3 className='text-5xl mb-5 text-black'>FIRST SPINACH THEN THE POWER</h3>
              <h4 className='font-asap max-w-2xl'><span className='font-semibold'>$PEYE</span> is the No. 1 community token allowing degens to strengthen their meme-token portfolio. 
              <span className='font-semibold'> Buy $PEYE</span> - eat spinach, and gain!</h4>
            </div>
            <div className='relative lg:block hidden'>
              <img src='img/popeye-image.png' className='absolute top-1/2 -translate-y-1/2'/>
            </div>
          </div>
        </div>
      </div>


      <div className='w-full px-5 py-20  mx-auto' id="Tokonomics">
      <h3 className='text-5xl  mb-6 text-black text-center'>Tokenomics</h3>
        <div className='bg-brandRed md:px-16 md:py-12 p-5 rounded-3xl border border-black overflow-x-hidden'>
          <div className='grid lg:grid-cols-3 gap-4 justify-center'>
            <div className='lg:hidden block ' data-aos="fade-left">
              <img src='img/mobile-pie.svg' className='mx-auto md:mb-0 mb-5'/>
              <div className='flex items-center justify-start'>
                <img src='img/anchor-1.png' className='mr-3'/>
                <h4 className='text-3xl  my-3 text-white'>61% DEX liquidity</h4>
              </div>
              <div className='flex items-center justify-start'>
                <img src='img/anchor-2.png' className='mr-3'/>
                <h4 className='text-3xl  my-3 text-white'>29% Locked wallet</h4>
              </div>
              <div className='flex items-center justify-start'>
                <img src='img/anchor-3.png' className='mr-3'/>
                <h4 className='text-3xl  my-3 text-white'>10% CEX liquidity</h4>
              </div>
            </div>
            <div className='col-span-1 text-left lg:flex hidden items-center flex-col justify-center' data-aos="fade-right" >
              <ul className='text-white md:px-0 px-5'>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> 61% Circulating Supply</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> 29% Locked Supply</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> 10% For CEX Listings</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> Stealth Launch</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> DEX Liquidity Locked</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> Contract on Multi-sig Wallet</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> No. 1 Wallet Locked</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> No Presale</li>
                <li className='font-asap text-2xl flex items-center'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> No Taxes</li>
              </ul>
            </div>

            <div className='lg:hidden col-span-1 text-left flex items-center flex-col justify-center' data-aos="fade-right" >
              <ul className='text-white md:px-0 font-semibold'>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> 61% Circulating Supply</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> 29% Locked Supply</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> 10% For CEX Listings</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> Stealth Launch</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> DEX Liquidity Locked</li>
                <li className='font-asap text-2xl flex items-center md:mb-2'><span className='inline-block mr-4'><img src='img/check-mark.svg'/></span> Multi-sig Wallet</li>
               
              </ul>
            </div>

            <div className='relative col-span-2 lg:block hidden' data-aos="fade-left" >
              <img src='img/pie-chart.png' className=''/>
            </div>
          </div>
        </div>
      </div>



      {/* <div className='px-6 py-16' id='BuyPopeye'>
       
       <div className='tezt-center'>
       <img className="hidden md:inline-block" src="/img/swapsection-destop.png" alt="" />
       <img className="md:hidden inline-block w-full" src="/img/swapsection-mobile.png" alt=""  />
       </div>
       </div> */}
       <div className='w-full pt-5' id='BuyPopeye'>
       <h2 className='text-5xl  mb-6 text-black text-center'>Prepare for launch!</h2>
      <div  data-aos="slide-up" className='px-6 py-16 md:p-14 bg-[#FBE45B] text-[#232021] text-center relative border border-black rounded-3xl mx-6 mtop50'>
                    
          <div  className='rounded-full py-2 px-8 bg-white border border-black text-3xl font-bold absolute left-0 right-0 mx-auto -top-6 text-center w-24'>1</div>
            <h2 className='text-4xl md:text-5xl mb-5'>GET A WALLET</h2>
            <p className='max-w-5xl mx-auto fontf text-xl md:text-2xl'>Install MetaMask, Trust Wallet, or any other suitable crypto wallet from the App Store or Google Play. Desktop users can install the Chrome extension from MetaMask.io.</p>
        </div>
      <div data-aos="slide-up" className='px-6 py-16 md:p-14 bg-[#FBE45B] text-[#232021] text-center relative border border-black rounded-3xl mx-6 mtop50'>
          <div className='rounded-full py-2 px-8 bg-white border border-black text-3xl font-bold absolute left-0 right-0 mx-auto -top-6 text-center w-24'>2</div>
            <h2 className='text-4xl md:text-5xl mb-5'>SEND ETH TO WALLET</h2>
            <p className='max-w-5xl mx-auto fontf text-xl md:text-2xl'>To buy <span className='font-bold'> $PEYE </span>, you’ll need ETH in your wallet to swap. Buy ETH directly in MetaMask, transfer from another wallet, or buy on an exchange and withdraw it to your wallet. Use the ERC-20 network.</p>
        </div>
      <div data-aos="slide-up" className='px-6 py-16 md:p-14 bg-[#FBE45B] text-[#232021] text-center relative border border-black rounded-3xl mx-6 mtop50'>
          <div className='rounded-full py-2 px-8 bg-white border border-black text-3xl font-bold absolute left-0 right-0 mx-auto -top-6 text-center w-24'>3</div>
            <h2 className='text-4xl md:text-5xl mb-5'>CONNECT TO UNISWAP</h2>
            <p className='max-w-5xl mx-auto fontf text-xl md:text-2xl'>By completing steps 1 and 2, you're all set and prepared for when the contract goes live. We appreciate your enthusiasm and commitment to #PopeyeCoin! Please note that when we launch, the how-to-buy steps will be updated to ensure a smooth purchasing process. Stay tuned for the exciting announcement and be ready to embark on this incredible journey with us.</p>
            {/* <p className='fontf text-xl md:text-2xl font-bold mt-7 text-center break-words '>0x000000000000000000000000000000000000000</p> */}
        </div>
        {/* <div className='px-6 py-16 md:p-14 bg-[#FBE45B] text-[#232021] text-center relative border border-black rounded-3xl mx-6 mtop50'>
          <div className='rounded-full py-2 px-8 bg-white border border-black text-3xl font-bold absolute left-0 right-0 mx-auto -top-6 text-center w-24'>4</div>
            <h2 className='text-4xl md:text-5xl mb-5'>SWAP ETH FOR $PEYE</h2>
            <p className='max-w-5xl mx-auto fontf text-xl md:text-2xl'>Once you have ETH in your wallet, swap your desired amount for <span className='font-semibold'>$PEYE</span>. Make sure you have enough ETH left over to cover gas fees. Review the transaction details and confirm. 
You’re now gaining strenkth and vitaliky!</p>
        </div> */}

</div>
      <div className='md:p-6 mtop100 relative' id="airdrop">
        <div className='bg-white p-6 md:p-12 md:max-w-[50vw] 2xl:max-w-2xl rounded-3xl border border-black mx-4 md:mx-0' data-aos="slide-up" data-aos-delay="200">
           <h2 className='text-5xl  mb-5'>Airdrop Incoming!</h2>
           <p className='fontf text-2xl mb-20'>Stay in the loop! Follow us on social media for the latest updates, exciting news, and future developments.</p>
           <a className='text-4xl  mb-5' href='https://t.me/PopeyeCoinWasTaken'>Join Telegram</a>
           <div className='text-center'>
               <a href='https://t.me/PopeyeCoinWasTaken' className='inline-block'><img className="" src="/img/socialicon3.svg" alt="" /></a>
           </div>
        </div>
             <img className="absolute right-7 top-24  md:block hidden w-[50vw]" src="/img/airdrop-incoming-desk.svg" alt="" data-aos-delay="200" data-aos="slide-up" />
             <img className="inline-block md:hidden mx-auto relative -mt-20 left-0 right-0 pointer-events-none" data-aos="slide-up" src="/img/airdrop-incoming-mobile.png" alt="" data-aos-delay="200" />
      </div>
      <div className='p-6 mtop200' id='RoadMap'>
      <h2 className='text-5xl  mb-5'>ROADMAP</h2>
      <p className='max-w-4xl mx-auto fontf text-2xl mb-20'>The ultimate goal of <span className='font-semibold'>$PEYE</span> is to obtain as much spinach as possible, gain strenkth and vitaliky and then surpass all competition.</p>
      <div className='container mx-auto'>
        <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-6 xl:gap-16'>
            <div className='relative py-16 px-8 border border-black bg-[#018CD7] rounded-3xl mb-20 lg:mb-0' data-aos="slide-up">
              <div className='absolute text-[#232021]  text-3xl bg-white rounded-full py-3 px-12 border border-black -top-7 left-0 right-0 mx-auto inline-block max-w-[230px]'>Phase 1</div>
              <ul className='fontf text-left'>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /> <span>Stealth Launch</span></li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>Lock Liquidity </span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>Lock #1 Wallet </span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>Renounce Contract </span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>Social Buzz </span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>1000 Holders </span> </li>
              </ul>
            </div>
            <div className='relative py-16 px-8 border border-black bg-[#018CD7] rounded-3xl mb-20 lg:mb-0' data-aos="slide-up">
              <div className='absolute text-[#232021]  text-3xl bg-white rounded-full py-3 px-12 border border-black -top-7 left-0 right-0 mx-auto inline-block max-w-[230px]'>Phase 2</div>
              <ul className='fontf text-left'>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /> <span>CEX Listings</span></li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>Social Takeover</span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>Strategic Partnerships</span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>Trust Wallet Listing </span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>10,000 Holders </span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>$100M Market Cap</span> </li>
              </ul>
            </div>
            <div className='relative py-16 px-8 border border-black bg-[#018CD7] rounded-3xl' data-aos="slide-up">
              <div className='absolute text-[#232021]  text-3xl bg-white rounded-full py-3 px-12 border border-black -top-7 left-0 right-0 mx-auto inline-block max-w-[230px]'>Phase 3</div>
              <ul className='fontf text-left'>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /> <span>Tier 1 CEX Listings</span></li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>Social Domination </span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>100,000 Holders</span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>$1B Market Cap </span> </li>
                <li className='flex align-middle gap-2 text-white text-2xl mb-2'><img className="" src="/img/iconuncheck.svg" alt="" /><span>Airdrop to Holders </span> </li>
              </ul>
            </div>
        </div>
      </div>
      </div>

    <div className='p-6 md:p-14 bg-[#FBE45B] text-[#232021] text-center border border-black rounded-3xl mx-6 mtop100' data-aos="zoom-in">
      <h2 className='text-5xl  mb-5'>disclaimer</h2>
      <p className='max-w-6xl mx-auto fontf text-xl md:text-2xl'><span className='font-semibold'>$PEYE</span> coin has no association with Bud Sagendorf or his creation Popeye the Sailor. This token is simply paying homage to a meme we all love and recognise. <span className='font-semibold'>$PEYE</span> is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. The coin is completely useless and for entertainment purposes only.</p>
    </div>



     
     <div className='pt-0 pb-12 mtop100'>
        <div className='text-center'>
          <img className="mx-auto inline-block" src="/img/popeyelogo.svg" alt="" data-aos="zoom-in"  />
        </div>
        <div className='flex align-middle items-center justify-center gap-4 mt-20 mb-6 md:mb-12 px-8'>
          {/* <a href='#' className='block'><img src="/img/socialicon1.svg" alt="" /></a> */}
          <a href='https://twitter.com/PopeyeWasTaken' className='block'  target='_blank'><img src="/img/socialicon2.svg" alt="" /></a>
          <a href='https://t.me/PopeyeCoinWasTaken' target='_blank' className='block'><img src="/img/socialicon3.svg" alt="" /></a>
          <a href='#' className='block'  target='_blank'><img src="/img/socialicon4.svg" alt="" /></a>
          {/* <a href='#' className='block'><img src="/img/socialicon5.svg" alt="" /></a>
          <a href='#' className='block'><img src="/img/socialicon6.svg" alt="" /></a>
          <a href='#' className='block'><img src="/img/socialicon7.svg" alt="" /></a> */}
        </div>
        <div>
          <p className='text-center fontf'><a href='/' className='font-semibold text-[#018CD7]'>© 2023 $PEYE</a> All Rights Reserved.</p>
        </div>

     </div>


    </div>
  );
}

export default App;
